<template>
  <UiStatus :title="feeStatus" :success="successStatus" />
</template>

<script>
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import { FEE_STATUS } from '@src/constants/fee-constants.js'
export default {
  components: {
    UiStatus,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      FEE_STATUS,
      feeStatus: '',
    }
  },
  computed: {
    successStatus() {
      return [
        FEE_STATUS.DUE,
        FEE_STATUS.PAID,
        FEE_STATUS.PARTIALLY_PAID,
        FEE_STATUS.WAIVED,
      ].includes(this.status)
    },
  },
  created() {
    this.feeStatusColorAndTitle(this.status)
  },
  methods: {
    feeStatusColorAndTitle(status) {
      switch (status) {
        case FEE_STATUS.NOT_PAID:
          this.feeStatus = 'Due'
          break
        case FEE_STATUS.DELAY:
          this.feeStatus = 'P.Paid'
          break
        case FEE_STATUS.DUE:
          this.feeStatus = 'Due'
          break
        case FEE_STATUS.PAID:
          this.feeStatus = 'Paid'
          break
        case FEE_STATUS.LATE_PAID:
          this.feeStatus = 'Paid'
          break
        case FEE_STATUS.PARTIALLY_PAID:
          this.feeStatus = 'P.Paid'
          break
        case FEE_STATUS.WAIVED:
          this.feeStatus = FEE_STATUS.WAIVED_OFF
          break
      }
    },
  },
}
</script>
