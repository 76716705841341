const feeConstants = {
  receivableStatus: [
    { name: 'Not Paid', value: 'not_paid_at_all' },
    { name: 'Partialy Paid', value: 'partially_paid' },
  ],
  receivableDueStatus: [
    { name: 'Due Date Passed', value: 'due_date_passed' },
    { name: 'Due Date Not Passed', value: 'due_date_not_passed' },
  ],
  receivedStatus: [
    { name: 'Full Paid', value: 'fully_paid' },
    { name: 'Partialy Paid', value: 'partially_paid' },
    { name: 'Waived Off', value: 'waived' },
  ],
}
export const FEE_STATUS = {
  NOT_PAID: 'not_paid',
  DELAY: 'delay',
  DUE: 'due',
  PAID: 'paid',
  LATE_PAID: 'late_paid',
  PARTIALLY_PAID: 'partially_paid',
  WAIVED: 'waived',
  WAIVED_OFF: 'Waived off',
}

export default feeConstants
